import { Inject, Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MetaService } from "../../../../src/app/core/services/app/meta.service";
import { DOCUMENT, Location } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MetatagService {

  url = ''
  constructor(
    private meta: MetaService,
    public translate: TranslateService,
    private meta2: Meta,
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this.url = this.location.path()
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
       this.url = event.url
      }
    })
  }

  setMetaTag() {
    let metaTag = this.url
    if (metaTag.startsWith('/en') || metaTag.startsWith('/tr')) {
      metaTag = metaTag.substring(3);
    }

    switch (metaTag) {
     
      case '/amerikadaniste-advantage':
        this.meta.updateTitle(this.translate.instant('META_AI_ADVANTAGES'));
        break;
      case '/faq':
        this.meta.updateTitle(this.translate.instant('FAQ2'));
        break;
      case '/contact':
        this.meta.updateTitle(this.translate.instant('META_CONTACT'));
        break;
      case '/prices':
        this.meta.updateTitle(this.translate.instant('META_PRICE'));
        break;
      case '/how-it-works':
        this.meta.updateTitle(this.translate.instant('META_HOW_IT_WORKS'));
        break;
      case '/about-us':
        this.meta.updateTitle(this.translate.instant('META_ABOUT_US'));
        break;
      case '/privacy-policy':
        this.meta.updateTitle(this.translate.instant('META_PRIVACY_POLICY'));
        break;
      case '/cookies-policy':
        this.meta.updateTitle(this.translate.instant('META_COOKIES_POLICY'));
        break;
      case '/shopping':
        this.meta.updateTitle(this.translate.instant('META_SHOPPING'));
        break;
      case '/signin':
        this.meta.updateTitle(this.translate.instant('META_SIGIN'));
        break;
      case '/welcome':
        this.meta.updateTitle(this.translate.instant('META_WELCOME'));
        break;
      case '/signup':
        this.meta.updateTitle(this.translate.instant('META_SIGNUP'));
        break;
      case '/buy-for-me':
        this.meta.updateTitle(this.translate.instant('BUY_FOR_ME'));
        break;
      case '/user/mailbox/new-parcel':
        this.meta.updateTitle(this.translate.instant('META_NEW_PACKAGE_NOTIFY'));
        break;
      case '/user/mailbox/waiting-parcels':
        this.meta.updateTitle(this.translate.instant('META_WAITING_PACKAGES'));
        break;
      case '/user/mailbox/list':
        this.meta.updateTitle(this.translate.instant('META_MAILBOX_LIST'));
        break;
      case '/user/buy-for-me/new':
        this.meta.updateTitle(this.translate.instant('META_BFM_NEW'));
        break;
      case '/user/awards/history':
        this.meta.updateTitle(this.translate.instant('META_AWARDS_HISTORY'));
        break;
      case '/user/awards/recommend':
        this.meta.updateTitle(this.translate.instant('META_AWARDS_RECOMMEND'));
        break;
      case '/user/campaigns/list':
        this.meta.updateTitle(this.translate.instant('META_CAMPAIGN_LIST'));
        break;
      case '/user/my-account/my-membership-information':
        this.meta.updateTitle(this.translate.instant('META_MY_ACCOUNT'));
        break;
      case '/user/my-account/address-list':
        this.meta.updateTitle(this.translate.instant('META_MY_ADDRESSES'));
        break;
      case '/user/my-account/my-bills':
        this.meta.updateTitle(this.translate.instant('META_MY_BILLS'));
        break;
      case '/user/my-account/password':
        this.meta.updateTitle(this.translate.instant('META_PASSWORD'));
        break;
      case '/shopping/my-basket':
        this.meta.updateTitle(this.translate.instant('META_MY_BASKET'));
        break;
      case '/user/orders/shopping':
        this.meta.updateTitle(this.translate.instant('META_MY_SHOPPING'));
        break;
      case '/user/orders/buy-for-me':
        this.meta.updateTitle(this.translate.instant('META_BUY_FOR_ME_HEAD'));
        break;
      case '/user/orders/cargo-orders':
        this.meta.updateTitle(this.translate.instant('META_CARGO_ORDERS'));
        break;
      case '/user/buy-for-me/new-detail':
        this.meta.updateTitle(this.translate.instant('META_NEW_BFM_ORDER'));
        break;
      case '/user/buy-for-me/bfm-payment':
        this.meta.updateTitle(this.translate.instant('META_BFM_DETAIL'));
        break;
      case '/user/buy-for-me/bfm-summary':
        this.meta.updateTitle(this.translate.instant('META_BFM_ORDER_SUMMARY'));
        break;
      case '/user/buy-for-me/bfm-thank-you':
        this.meta.updateTitle(this.translate.instant('META_BFM_ORDER_COMPLETE'));
        break;
      case '/user/buy-for-me/bfm-thank-you':
        this.meta.updateTitle(this.translate.instant('META_BFM_ORDER_COMPLETE'));
        break;
      case '/shopping/signup-and-bfm':
        this.meta.updateTitle(this.translate.instant('META_SIGNUP_BFM'));
        break;
      case '/shopping/signup-and-bfm/success':
        this.meta.updateTitle(this.translate.instant('META_SIGNUP_BFM_SUCCESS'));
        break;
      case '/shopping/signup-and-continue-shopping':
        this.meta.updateTitle(this.translate.instant('META_CONTINUE_SHOPPING'));
        break;
      case '/shopping/signup-and-continue-shopping/payment':
        this.meta.updateTitle(this.translate.instant('META_CONTINUE_SHOPPING_PAYMENT'));
        break;
      case '/shopping/signup-and-continue-shopping/success':
        this.meta.updateTitle(this.translate.instant('META_CONTINUE_SHOPPING_SUCCESS'));
        break;
      case '/user/favourites/list':
        this.meta.updateTitle(this.translate.instant('META_FAVOURITES_LIST'));
        break;
      case '/':
        this.meta.updateTitle(this.translate.instant('META_MAIN_PAGE'));
        this.defaultMeta()
        break;
      case '':
        this.meta.updateTitle('Amerikadaniste');
        this.defaultMeta()
        break;
      case '/tr':
        this.meta.updateTitle(this.translate.instant('META_MAIN_PAGE'));
        this.defaultMeta()
        break;
      case '/en':
        this.meta.updateTitle(this.translate.instant('META_MAIN_PAGE'));
        this.defaultMeta()
        break;
      case '/home':
        this.meta.updateTitle(this.translate.instant('META_MAIN_PAGE'));
        this.defaultMeta()
        break;
      case '/swiss-otel-bosphorus-halloween-party-2023':
        this.meta.updateTitle('Swiss Otel Bosphorus Halloween Partisi 2023 | Amerikadaniste');
        this.meta2.updateTag({ property: 'og:title', content: 'Swiss Otel Bosphorus Halloween Partisi 2023 | Amerikadaniste' })
        this.meta2.updateTag({ property: 'og:description', content: 'Amerikadaniste, 28 Ekim 2023 te Swiss Otel Bosphorusta düzenlenecek olan muazzam Halloween partisinin sponsorlarından biridir. Bu özel etkinlik için biletlerinizi hemen alın!' })
        this.meta2.updateTag({ property: 'og:image', content: '' })
        this.meta2.updateTag({ property: 'og:url', content: 'https://www.amerikadaniste.com/events/swiss-otel-bosphorus-halloween-party-2023' })

        this.meta2.updateTag({ name: 'twitter:card', content: '' })
        this.meta2.updateTag({ name: 'twitter:title', content: 'Swiss Otel Bosphorus Halloween Partisi 2023 | Amerikadaniste' })
        this.meta2.updateTag({ name: 'twitter:description', content: 'Amerikadaniste, 28 Ekim 2023 te Swiss Otel Bosphorus ta düzenlenecek olan muazzam Halloween partisinin sponsorlarından biridir. Bu özel etkinlik için biletlerinizi hemen alın!' })
        this.meta2.updateTag({ name: 'twitter:card', content: '' })
        break;
      case '/amerikadaniste-golf-cup':
        this.meta.updateTitle('Amerikadaniste Golf Cup - Kemercountry Club Exclusive Sponsorship');
        this.meta2.updateTag({ name: 'keywords', content: 'Amerikadaniste, Golf Cup, Kemercountry Club, Exclusive Event, Premium Products, Special Discounts' })
        this.meta2.updateTag({ property: 'og:description', content: 'Join us at the Amerikadaniste Golf Cup, an exclusive event sponsored by Amerikadaniste in collaboration with Kemercountry Club. Explore premium products and enjoy special discounts during the event.' })
        this.meta2.updateTag({ property: 'og:title', content: 'Amerikadaniste Golf Cup - Exclusive Sponsorship Event' })
        this.meta2.updateTag({ property: 'og:url', content: 'https://www.amerikadaniste.com/amerikadaniste-golf-cup' })
        break;
      case '/longchamp':
        this.meta2.updateTag({ name: 'keywords', content: "Longchamp, Longchamp çanta,Sofistike aksesuarlar,Online çanta satışı,Özel indirimler,Şık tasarım çantalar,Amerika'dan alışveriş,Lüks ürünler,Sezon modası" })
        this.meta2.updateTag({ property: 'og:description', content: 'En yeni Longchamp çantaları ve aksesuarları için özel online mağazamızı ziyaret edin. Şık tasarımlar, cazip fiyatlar ve hızlı kargo hizmeti ile ayrıcalıklı alışverişin tadını çıkarın!' })
        break;
      case '/golden-goose':
        this.meta2.updateTag({ name: 'keywords', content: "Golden Goose, Amerikadaniste, Online Satış, Online Alışveriş, Amerika'dan kolay Alışveriş, Türkiye, Hızlı Kargo, Güvenli Alışveriş" })
        this.meta2.updateTag({ property: 'og:description', content: "Golden Goose ürünlerini Amerikadaniste ile Amerika'dan 3-5 iş günü içinde satın alın. İstanbul ve Türkiye'ye hızlı kargo!" })
        break;
      default:
        let brand = metaTag.split('/').pop() || ''
        brand = brand.replace(/-/g, ' ').replace(/_/g, ' ')
        if (brand.toLowerCase() == 'abercrombie') { brand = 'Abercrombie & Fitch' }
        if (brand.toLowerCase() == 'babies r us') { brand = "Babies 'R' Us" }
        if (brand.toLowerCase() == 'bloomingdale s') { brand = "Bloomingdale's" }
        if (brand.toLowerCase() == 'chef s planet') { brand = "Chef's Planet" }
        if (brand.toLowerCase() == 'clean easy') { brand = 'Clean + Easy' }
        if (brand.toLowerCase() == 'd g') { brand = 'D&G' }
        if (brand.toLowerCase() == 'daniel bob') { brand = 'Daniel & Bob' }
        if (brand.toLowerCase() == 'dolce gabbana') { brand = 'Dolce & Gabbana' }
        if (brand.toLowerCase() == 'harry s of london') { brand = "Harry's of London" }
        if (brand.toLowerCase() == 'hershey s') { brand = "Hershey's" }
        if (brand.toLowerCase() == 'johnson johnson') { brand = 'Johnson & Johnson' }
        if (brand.toLowerCase() == 'johnson s baby') { brand = "Johnson's Baby" }
        if (brand.toLowerCase() == 'kiehl s') { brand = "Kiehl's" }
        if (brand.toLowerCase() == 'kohl s') { brand = "Kohl's" }
        if (brand.toLowerCase() == 'l oreal') { brand = "L'Oreal" }
        if (brand.toLowerCase() == 'land s end') { brand = "Land's End" }
        if (brand.toLowerCase() == 'levi s') { brand = "Levi's" }
        if (brand.toLowerCase() == 'loehmann s') { brand = "Loehmann's" }
        if (brand.toLowerCase() == 'macy s') { brand = "Macy's" }
        if (brand.toLowerCase() == 'mrs mayer s') { brand = "Mrs. Meyer's" }
        if (brand.toLowerCase() == 'sophiasstyle') { brand = "Sophia's Style" }
        if (brand.toLowerCase() == 'children s place') { brand = "The Children's Place" }
        if (brand.toLowerCase() == 'victoria s secret') { brand = "Victoria's Secret" }
        if (brand.toLowerCase() == 'new york co') { brand = "New York & Co." }
        if (brand.toLowerCase() == 'o neill') { brand = "O'Neill" }
        if (brand.toLowerCase() == 'oral b') { brand = "Oral-B" }
        if (brand.toLowerCase() == 'paul shark') { brand = "Paul & Shark" }
        if (brand.toLowerCase() == 'quark s') { brand = "Quark's" }
        if (brand.toLowerCase() == 'ray ban') { brand = "Ray-Ban" }
        if (brand.toLowerCase() == 'tiffany co') { brand = "Tiffany & Co." }
        if (brand.toLowerCase() == 'tod s') { brand = "Tod's" }
        if (brand.toLowerCase() == 'toys r us') { brand = "Toys 'R' Us" }
        if (brand.toLowerCase() == 'warner s') { brand = "Warner's" }
        if (brand.toLowerCase() == 'white warren') { brand = "White + Warren" }
        if (brand.toLowerCase() == 'whiting davis') { brand = "Whiting & Davis" }
        if (brand.toLowerCase() == 'woody s') { brand = "Woody's" }
        if (brand.toLowerCase() == 'yin yang') { brand = "Yin & Yang" }
        this.meta.updateTitle(brand);
        this.meta2.updateTag({ name: 'description', content: this.translate.instant('META_DESC') + ' ' + brand })
        this.meta2.updateTag({ property: 'og:description', content: this.translate.instant('META_DESC') + ' ' + brand })
        this.meta2.updateTag({ property: 'og:locale', content: this.translate.currentLang == 'tr' ? 'tr' : 'en' })
        this.meta2.updateTag({ name: 'keywords', content: this.translate.instant('META_KEYWORDS') })
        break;
    }

    if (metaTag.includes('/user/mailbox/list/details/step-1')) {
      this.meta.updateTitle(this.translate.instant('META_CREATE_CARGO_ORDER'));
    }
    if (metaTag.includes('/user/mailbox/list/details/step-2')) {
      this.meta.updateTitle(this.translate.instant('META_CARGO_ORDER_DETAIL'));
    }
    if (metaTag.includes('/user/mailbox/list/details/step-3')) {
      this.meta.updateTitle(this.translate.instant('META_CARGO_ORDER_PAYMENT'));
    }
    if (metaTag.includes('/user/mailbox/list/details/step-4')) {
      this.meta.updateTitle(this.translate.instant('META_CARGO_ORDER_SUMMARY'));
    }
    if (metaTag.includes('/user/mailbox/list/details/step-5')) {
      this.meta.updateTitle(this.translate.instant('META_CARGO_ORDER_SUCCESS'));
    }
    if (metaTag.includes('/user/mailbox/list/details/step-5')) {
      this.meta.updateTitle(this.translate.instant('META_CARGO_ORDER_SUCCESS'));
    }
    if (metaTag.includes('/shopping/order')) {
      this.meta.updateTitle(this.translate.instant('META_ORDER_TO_COMPLETE'));
    }
    if (metaTag.includes('/shopping/order/payment')) {
      this.meta.updateTitle(this.translate.instant('META_ORDER_PAYMENT'));
    }
    if (metaTag.includes('/shopping/order/summary')) {
      this.meta.updateTitle(this.translate.instant('META_ORDER_SUMMARY'));
    }
    if (metaTag.includes('/shopping/order/complete')) {
      this.meta.updateTitle(this.translate.instant('META_ORDER_COMPLETE'));
    }
    if (metaTag.includes('/shopping/product')) {
      this.meta.updateTitle(this.translate.instant('META_SHOPPING_PRODUCT'));
    }
    if (metaTag.includes('/reliable-address-for-shopping-from-america')) {
      this.meta.updateTitle(this.translate.instant('META_AI_TRUST_TITLE'));
      this.meta2.updateTag({ property: 'og:description', content: this.translate.instant('META_AI_TRUST_DESC') })
      this.meta2.updateTag({ name: 'description', content: this.translate.instant('META_AI_TRUST_DESC') })
    }
    if (metaTag.includes('/kuveytturk')) {
      this.meta.updateTitle("Kuveyt Türk'le Amerika'dan Alışveriş Fırsatı - Amerikadaniste");
      this.meta2.updateTag({ property: 'og:description', content: "Kuveyt Türk müşterilerine özel Amerika'dan alışveriş fırsatları Amerikadaniste'de! Ücretsiz üyelik ve indirim fırsatları ile istediğin ürünü Amerika'dan al!" })
    }
    if (metaTag.includes('/halloween')) {
      if (this.translate.currentLang == 'tr') {
        this.meta.updateTitle("Cadılar Bayramı Kostüm & Dekorasyon Fırsatları - Amerikadaniste");
        this.meta2.updateTag({ name: 'description', content: "Cadılar Bayramı alışverişlerinizi Amerikadaniste ile güvenle yapın! ABD’den Türkiye’ye güvenli teslimat ve özel fırsatlarla Halloween'i kutlayın!" })
        this.meta2.updateTag({ name: 'keywords', content: 'Cadılar Bayramı, Halloween, Amerikadaniste, ABD’den alışveriş, Türkiye teslimat, indirim' })
      } else {
        this.meta.updateTitle("Halloween Costume & Decoration Deals - Amerikadaniste");
        this.meta2.updateTag({ name: 'description', content: 'Shop the best Halloween costume & decoration deals with Amerikadaniste! Enjoy fast, secure and seamless international delivery from the USA to Turkey' })
        this.meta2.updateTag({ name: 'keywords', content: 'Halloween, Amerikadaniste, shopping from the US, delivery to Turkey, discounts, offers' })
      }
    }

    if (metaTag.includes('abd-alisveris-gumruk')) {
      this.meta.updateTitle("Amerikadaniste ile Gümrük İşlemleri Kolaylaştı - Alışverişin Keyfini Çıkarın");
      this.meta2.updateTag({ name: 'description', content: 'Amerikadaniste ile Amerika’dan Türkiye’ye hızlı ve güvenli alışveriş yapın. 30 Euro üzeri alışverişlerinizde profesyonel gümrük işlemleri desteği ile sorunsuz bir alışveriş deneyimi yaşayın.' })
      this.meta2.updateTag({ name: 'keywords', content: 'Amerikadaniste, Türkiye gümrük kuralları, uluslararası alışveriş, ABD’den Türkiye’ye teslimat, ETGB kargo, 30 Euro ithalat kuralı, sorunsuz alışveriş, ithalat prosedürleri, güvenli alışveriş hizmeti' })
    }

    if (metaTag.includes('/shopping/product')) {
      this.meta2.addTag({ name: 'referrer', content: 'no-referrer' })
    } else {
      this.meta2.removeTag("name='referrer'")
    }
    this.updateCanonicalUrl('https://www.amerikadaniste.com' + metaTag)

  };

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element = this.dom.querySelector(`link[rel='canonical']`)
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  defaultMeta() {
    this.meta.updateTitle(this.translate.instant('META_MAIN_PAGE'));
    this.meta2.updateTag({ name: 'description', content: this.translate.instant('META_DESC') })
    this.meta2.updateTag({ property: 'og:description', content: this.translate.instant('META_DESC') })
    this.meta2.updateTag({ property: 'og:locale', content: this.translate.currentLang == 'tr' ? 'tr' : 'en' })
    this.meta2.updateTag({ name: 'keywords', content: this.translate.instant('META_KEYWORDS') })
    this.meta2.updateTag({ property: 'og:title', content: this.translate.instant('META_MAIN_PAGE') })
  }
}